import DataTable from "../../../components/common/DataTable";
import { useQuery } from "react-query";

import PositionedSnackbar from "../../../components/common/PositionedSnackbar";
import {  useSelector } from "react-redux";
import "../../../styles/common.scss";
import TopBar from "../../../components/common/TopBar";
import Loader from "../../../components/common/Loader";
import { selectSnackBarMessage } from "../../../store/commonSlice/commonSlice";
import fuelTypeServices from "../../../services/fuelType.Services/fuelType.Services";
import AddFuelType from "../../../components/car-components/fuel-type-components/AddFuelType";
import DeleteFuelType from "../../../components/car-components/fuel-type-components/DeleteFuelType";

import UpdateFuelType from "../../../components/car-components/fuel-type-components/UpdateFuelType";
import { useMemo } from "react";

const FuelType = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);

  const { isLoading, isError, data } = useQuery(
    ["fuelType"],
    fuelTypeServices.getAllFuelTypeService,
    {
      onError: (error) => {
        const responce = error;
        console.log(error);
        console.error(responce.message);
      },
    }
  );

  const columns = [
    {
      field: "id",

      flex: 1,

      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "fuelType",
      headerName: "Fuel type",

      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,

      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => {
        return <UpdateFuelType fuelType={params.row} />;
      },

      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteFuelType fuelType={params.row} />,
    },
  ];

  // Create a row data object for the data table

  function createData(id, fuelType) {
    return { id, fuelType };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data.data.data.map((item, index) => {
        return createData(item._id || index + 1, item.fuelType);
      });
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Fuel type "
        SubHeaderName="Manage all car fuel types from here"
        SearchLabel="Search fuel type"
      />
      <div className="table-top-button">
        <AddFuelType />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No fuel type</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default FuelType;
