import { useMemo } from "react";
import DataTable from "../../../components/common/DataTable";
import { useQuery } from "react-query";

import PositionedSnackbar from "../../../components/common/PositionedSnackbar";
import {  useSelector } from "react-redux";
import "../../../styles/common.scss";

import TopBar from "../../../components/common/TopBar";
import Loader from "../../../components/common/Loader";

import { selectSnackBarMessage } from "../../../store/commonSlice/commonSlice";

import engineSizeServices from "../../../services/engineSize.services/engineSizeServices";
import AddEngineSize from "../../../components/car-components/engine-size-components/AddEngineSize";
import DeleteEngineSize from "../../../components/car-components/engine-size-components/DeleteEngineSize";

import UpdateEngineSize from "../../../components/car-components/engine-size-components/UpdateEngineSize";

const EngineSize = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);

  const { isLoading, isError, data } = useQuery(
    ["engineSize"],
    engineSizeServices.getAllEngineSizeService,
    {
      onError: (error) => {
        const responce = error;
        console.log(error);
        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => [
    {
      field: "id",

      flex: 1,

      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "engineSize",
      headerName: "Engine size",

      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,

      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => {
        return <UpdateEngineSize engineSize={params.row} />;
      },

      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteEngineSize engineSize={params.row} />,
    },
  ]);

  // Create a row data object for the data table
  function createData(id, engineSize) {
    return { id, engineSize };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data.data.data.map((item, index) => {
        return createData(item._id || index + 1, item.engineSize);
      });
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Engine size"
        SubHeaderName="Manage all car engine sizes from here"
        SearchLabel="Search engine size"
      />
      <div className="table-top-button">
        <AddEngineSize />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No engine size</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default EngineSize;
