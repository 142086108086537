import DataTable from "../../components/common/DataTable";
import { useQuery } from "react-query";
import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import { useSelector } from "react-redux";
import "../../styles/common.scss";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import locationServices from "../../services/locations.services/locations.Services";
import AddLocation from "../../components/locations-components/AddLocation";
import DeleteLocation from "../../components/locations-components/DeleteLocation";
import UpdateLocation from "../../components/locations-components/UpdateLocation";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const Locations = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["location"],
    locationServices.getAllLocationService,
    {
      onError: (error) => {
        const responce = error;
        console.log();
        console.error(responce.message);
      },
    }
  );

  const columns = [
    {
      field: "id",

      flex: 1,
      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "locationName",
      headerName: "LOcation name",

      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "locationCode",
      headerName: "Location code",

      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "locationShortLink",
      headerName: "Location link",

      flex: 1,
      headerClassName: "column-header ",
      renderCell: (value) => (
        <Link to={value.row.locationShortLink} target="_blank">
          {value.row.locationShortLink}
        </Link>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,

      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => <UpdateLocation location={params.row} />,

      align: "left",
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 0.5,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteLocation location={params.row} />,
    },
  ];

  // Create a row data object for the data table
  function createData(id, locationName, locationCode, locationShortLink) {
    return { id, locationName, locationCode, locationShortLink };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data?.data) {
      return data?.data?.data.map((item, index) => {
        return createData(
          item._id || index + 1,
          item.locationName,
          item.locationCode,
          item.locationShortLink
        );
      });
    }
    return [];
  }, [data, isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Locations"
        SubHeaderName="Manage all locations from here"
      />
      <div className="table-top-button">
        <AddLocation />
      </div>

      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No users</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default Locations;
