import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { SnackBarMessageAction } from "../../../store/commonSlice/commonSlice";
import engineOriginServices from "../../../services/engineOrigin.services/engineOrigin.services";
import { useState } from "react";
import { useRef } from "react";


// Transition component for the dialog animation
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteEngineOrigin = ({ engineOrigin }) => {

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const buttonRef = useRef(null);

 // Define a mutation for deleting using react-query
  const deleteMutation = useMutation(
    engineOriginServices.deleteEngineOriginService,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("engineOrigin");
        dispatch(SnackBarMessageAction("Engine origin Deleted Successfully"));
      },
      onError: (error) => {
        const responce = error;
        dispatch(SnackBarMessageAction(" Delete Failed"));

        console.error(responce.message);
      },
    }
  );
  // delete Engine Origin
  const handleDelete = (engineOrgineID) => {
    buttonRef.current.disabled = true;
    deleteMutation.mutate(engineOrgineID);
  };
  
  //handle modal 
  const [open, setOpen] = useState(false);
  const handleClick = (value) => {
    setOpen(value);
  };
  return (
    <div>
      <DeleteIcon style={{ cursor: "pointer" }} onClick={()=>handleClick(true)} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>handleClick(false)}
        className="deletebox"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="headName">Are you sure?</DialogTitle>
        <DialogContent className="deleteContent">
          <DialogContentText className="headName">
            You are about to delete {engineOrigin.engineOrigin}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-buttons" onClick={()=>handleClick(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleDelete(engineOrigin.id);
            }}
            className="delete-buttons"
            ref={buttonRef}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DeleteEngineOrigin;
