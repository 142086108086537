import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMemo } from "react";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";

import Alerts from "../common/Alerts";
import locationServices from "../../services/locations.services/locations.Services";
import { useRef } from "react";

export default function UpdateLocation({ location }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const buttonRef = useRef(null);

  //validation and form using react hook form
  const [defaultValue, setDefault] = useState(location);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

  //submit form with data
  const onSubmit = async (value) => {
    buttonRef.current.disabled = true;

    const updateData = {
      id: location?.id,
      locationName: value.locationName,
      locationCode: value.locationCode,
      locationShortLink: value.locationShortLink,
    };
    try {
      await mutation.mutateAsync(updateData);
      setDefault(updateData);
    } catch (error) {
      console.error(error);
    }
  };

  const onModalState = (state) => {
    setIsModal(state);
    setErrorMessage(null);
    !state && reset(defaultValue);
  };

  //updating location using react query
  const mutation = useMutation(locationServices.updateLocationService, {
    onSuccess: () => {
      setIsModal(false);
      dispatch(SnackBarMessageAction("Location changed Successfully"));
      queryClient.invalidateQueries("location");
    },
    onError: (error) => {
      const responce = error;
      console.error(responce.message);
      setErrorMessage("network Error");
    },
  });

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Update location</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Location Name"
                fullWidth
                {...register("locationName", {
                  required: "Location name is required",
                })}
              />
              {errors.locationName && (
                <p className="error-message">{errors.locationName.message}</p>
              )}
              <TextField
                label="Location code"
                fullWidth
                {...register("locationCode", {
                  required: "Location code is required",
                })}
              />
              {errors.locationCode && (
                <p className="error-message">{errors.locationCode.message}</p>
              )}
              <TextField
                label="Location link"
                fullWidth
                {...register("locationShortLink", {
                  required: "Location link is required",
                })}
              />
              {errors.locationShortLink && (
                <p className="error-message">
                  {errors.locationShortLink.message}
                </p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
