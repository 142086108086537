import { useState, useMemo } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import brandServices from "../../../services/brand.services/brandServices";
import Alerts from "../../common/Alerts";
import { SnackBarMessageAction } from "../../../store/commonSlice/commonSlice";
import { useRef } from "react";

const UpdateBrand = ({ brand }) => {
  const [isModal, setIsModal] = useState(false);

  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultValue, setDefault] = useState(brand);
  const buttonRef = useRef(null);

 // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

// Function to handle form submission
  const onSubmit = async (value) => {
    buttonRef.current.disabled = true;
   
    const updateData = {
      id: brand?.id,
      brand: value.brand,
    };
    try {
      await mutation.mutateAsync(updateData);
      setDefault(updateData);
    } catch (error) {
      console.error(error);
    }
  };

// Function to control the dialog's open and close state
  const onModalState = (state) => {
    setIsModal(state);
    setErrorMessage(null);
    !state && reset(defaultValue);
  };


  //Updating brand using react query

  const mutation = useMutation(brandServices.updateBrand, {
    onSuccess: (data) => {
      console.log("Item added successfully:", data);
      setIsModal(false);
      dispatch(SnackBarMessageAction("Brand Added Successfully"));
      queryClient.invalidateQueries("brands");
    },
    onError: (error) => {
      const responce = error;
      console.error(responce.message);
      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />

      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title"> Update brand</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Brand Name"
                fullWidth
                {...register("brand", { required: "Brand name is required" })}
              />
              {errors.brand && (
                <p className="error-message">{errors.brand.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateBrand;
