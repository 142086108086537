import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useMemo } from "react";

export default function ViewAppointMent({ appointment }) {
  const [isModal, setIsModal] = useState(false);

  const [defaultValue] = useState(appointment);

  const { register } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

  // Function to control the dialog's open and close state
  const onModalState = (state) => {
    setIsModal(state);
  };

  return (
    <>
      <ViewListIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />
      <Dialog
        className="dialog"
        open={isModal}
      >
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Appointment</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div
            style={{
              display: "flex",
              width: "550px",
              
            }}
          >
            <form
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "10px",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.keys(defaultValue).map((field) => (
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      width: "250px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  key={field}
                  label={field}
                  {...register(field)}
                  disabled
                />
              ))}
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
