import axiosInstance from "../../helpers/auth.helper/auth.helper";

const fuelTypeServices = {
  createFuelTypeService: (data) => axiosInstance.post("/admin/cars/fuelType/add", data),
  getAllFuelTypeService: () => axiosInstance.get("/admin/cars/fuelType/"),
  updateFuelTypeService: (data) => axiosInstance.put(`/admin/cars/fuelType/${data.id}`, data),
  deleteFuelTypeService: (data) => axiosInstance.delete(`/admin/cars/fuelType/${data}`),
};

export default fuelTypeServices;
