import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { SnackBarMessageAction } from "../../../store/commonSlice/commonSlice";
import { useMutation, useQueryClient } from "react-query";

import { useState } from "react";
import { useForm } from "react-hook-form";
import Alerts from "../../common/Alerts";
import engineSizeServices from "../../../services/engineSize.services/engineSizeServices";
import { useRef } from "react";

const AddEngineSize = () => {

  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const buttonRef = useRef(null);

  
 // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function to handle form submission
  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    mutation.mutate(data);
  };

  // Handle the modal's open/close state
  const onModalState = (state) => {
    setIsModal(state);
    reset();
    setErrorMessage(null);
  };


  // Define a mutation for creation using react-query
  const mutation = useMutation(engineSizeServices.createEngineSizeService, {
    onSuccess: (data) => {
      console.log("Item added successfully:", data);
      onModalState(false);
      dispatch(SnackBarMessageAction("Engine size Added Successfully"));
      queryClient.invalidateQueries("engineSize");
    },
    onError: (error) => {
      const responce = error;
      console.log();
      console.error(responce.message);

      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  return (
    <>
      <Button
        className="common-button-style"
        onClick={() => onModalState(true)}
      >
        Add Engine size
      </Button>

      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">New Engine size</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Engine size Name"
                fullWidth
                {...register("engineSize", {
                  required: "Engine size name is required",
                })}
              />
              {errors.engineSize && (
                <p className="error-message">{errors.engineSize.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEngineSize;
