
import DataTable from "../../components/common/DataTable";
import { useQuery } from "react-query";
import { Link,  } from "react-router-dom";
import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import { useDispatch, useSelector } from "react-redux";
import carServiceServices from "../../services/carService.services/carServiceServices";
import AddCarService from "../../components/car-services-components/AddCarService";
import DeleteCarService from "../../components/car-services-components/DeleteCarService";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";

import { SelectedCarServiceAction } from "../../store/carServiceSlice/carServiceSlice";
import { useMemo } from "react";
import UpdateCarService from "../../components/car-services-components/UpdateCarService";

const CarServices = () => {
  const dispatch = useDispatch();
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["carServices"],
    carServiceServices.getAllCarServices,
    {
      onError: (error) => {
        const responce = error;
        console.log();
        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => [
    {
      field: "id",
    
      flex: 1,

      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "serviceName",
      headerName: "Service Name",

      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "subServices",
      headerName: "Sub Services",
      sortable: false,
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => {
        return (
          <Link
            style={{ textDecoration: "none", color: "#f9ce00" }}
            to={`/services/${params.row.id}`}
            onClick={() => {
              dispatch(SelectedCarServiceAction(params.row));
            }}
          >
            View Sub Services
          </Link>
        );
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,

      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => {
        return <UpdateCarService carService={params.row} />;
      },

      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteCarService carService={params.row} />,
    },
  ]);



  // Create a row data object for the data table
  function createData(id, serviceName) {
    return { id, serviceName };
  }


  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data?.data?.data.map((item, index) => {
        return createData(item._id || index + 1, item.serviceName);
      });
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }


  return (
    <div className="table-main">
      <TopBar
        HeaderName="Services"
        SubHeaderName="Manage all car services from here"
        SearchLabel="Search services"
      />
      <div className="table-top-button">
       
        <AddCarService />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No Services</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default CarServices;
