import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useForm } from "react-hook-form";
import brandServices from "../../../../services/brand.services/brandServices";
import { SnackBarMessageAction } from "../../../../store/commonSlice/commonSlice";
import Alerts from "../../../common/Alerts";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useRef } from "react";

const UpdateBrandModel = ({ carModel }) => {
  const [isModal, setIsModal] = useState(false);
  const { brandid } = useParams();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultValue, setDefault] = useState(carModel);
  const buttonRef = useRef(null);
  

 // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

 // Function to handle form submission
  const onSubmit = async(data) => {
    buttonRef.current.disabled = true;
  
    const updatedData = {
      modelid: carModel.id,
      brandid,
      ...data,
    };
    try {
      await mutation.mutateAsync(updatedData);
      setDefault(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

// Function to control the dialog's open and close state
  const onModalState = (state) => {
    setIsModal(state);
    setErrorMessage(null);
    !state && reset(defaultValue);
  };


 // Updating additional charge using React Query
  const mutation = useMutation(brandServices.updateBrandModel, {
    onSuccess: (data) => {
      setIsModal(false);
      dispatch(SnackBarMessageAction("Model updated Successfully"));
      queryClient.invalidateQueries("brandModels");
    },
    onError: (error) => {
      const responce = error;
      console.error(responce.message);

      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        
        setErrorMessage("network Error");
      }
    },
  });

  //validation and form using react hook form

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />

      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Update brand model</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Model Name"
                fullWidth
                {...register("carModel", {
                  required: "Brand model name is required",
                })}
              />
              {errors.carModel && (
                <p className="error-message">{errors.carModel.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateBrandModel;
