import { useMemo } from "react";
import DataTable from "../../../components/common/DataTable";
import { useQuery } from "react-query";

import PositionedSnackbar from "../../../components/common/PositionedSnackbar";
import {  useSelector } from "react-redux";
import "../../../styles/common.scss";
import TopBar from "../../../components/common/TopBar";
import Loader from "../../../components/common/Loader";
import { selectSnackBarMessage } from "../../../store/commonSlice/commonSlice";
import engineOriginServices from "../../../services/engineOrigin.services/engineOrigin.services";
import DeleteEngineOrigin from "../../../components/car-components/engine-origin-components/DeleteEngineOrigin";
import AddEngineOrigin from "../../../components/car-components/engine-origin-components/AddEngineOrigin";

import UpdateEngineOrigin from "../../../components/car-components/engine-origin-components/UpdateEngineOrigin";

const EngineOrigin = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);

  const { isLoading, isError, data } = useQuery(
    ["engineOrigin"],
    engineOriginServices.getAllEngineOriginService,
    {
      onError: (error) => {
        const responce = error;
        console.log(error);
        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => [
    {
      field: "id",

      flex: 1,

      headerName: "ID",
      headerClassName: "column-header ",
    },
    {
      field: "engineOrigin",
      headerName: "Engine Origin",

      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,

      headerClassName: "column-header ",
      renderCell: (params) => {
        return <UpdateEngineOrigin engineOrigin={params.row} />;
      },

      align: "left",
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteEngineOrigin engineOrigin={params.row} />,

      align: "left",
    },
  ]);

  // Create a row data object for the data table
  function createData(id, engineOrigin) {
    return { id, engineOrigin };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data.data.data.map((item, index) =>
        createData(item._id || index + 1, item.engineOrigin)
      );
    }
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Engine origin"
        SubHeaderName="Manage all car engine origins from here"
      />
      <div className="table-top-button">
        <AddEngineOrigin />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No Engine Origin</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default EngineOrigin;
