import React from "react";

const ErrorPage = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Oops, something went wrong!</h1>
      <p>The page you're looking for doesn't exist or an error occurred.</p>
      {/* You can add more content, links, or styling here */}
    </div>
  );
};

export default ErrorPage;
