import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import {  useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";
import Alerts from "../common/Alerts";
import locationServices from "../../services/locations.services/locations.Services";
import { useRef } from "react";

export default function AddLocation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const buttonRef = useRef(null);

  //validation and form using react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (value) => {
    buttonRef.current.disabled = true;
   
    mutation.mutate(value);
  };

  // Function to handle opening and closing the Modal
  const onModalState = (state) => {
    setIsModal(state);
    reset();
    setErrorMessage(null);
  };

  //updating location using react query
  const mutation = useMutation(locationServices.createLocationService, {
    onSuccess: (data) => {
      console.log("Item changed successfully:", data);
      onModalState(false);
      dispatch(SnackBarMessageAction("Location added Successfully"));
      queryClient.invalidateQueries("location");
    },
    onError: (error) => {
      const responce = error;
      console.error(responce.message);
      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  return (
    <>
      <Button
        className="common-button-style"
        onClick={() => onModalState(true)}
      >
        Add location
      </Button>
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">New location</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Location Name"
                fullWidth
                {...register("locationName", {
                  required: "Location name is required",
                })}
              />
              {errors.locationName && (
                <p className="error-message">{errors.locationName.message}</p>
              )}
              <TextField
                label="Location code"
                fullWidth
                {...register("locationCode", {
                  required: "Location code is required",
                })}
              />
              {errors.locationCode && (
                <p className="error-message">{errors.locationCode.message}</p>
              )}
               <TextField
                label="Location link"
                fullWidth
                {...register("locationShortLink", {
                  required: "Location link is required",
                })}
              />
              {errors.locationShortLink && (
                <p className="error-message">{errors.locationShortLink.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => {
                    onModalState(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
