import Router from "./Router";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const client = new QueryClient();
  return (
    <div className="app">
      <QueryClientProvider client={client}>
        <Router />
      </QueryClientProvider>
    </div>
  );
}

export default App;
