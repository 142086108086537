import React from "react";
import { NavLink } from "react-router-dom";
import "./leftsidebar.scss";

const SideBarItem = ({ name, redirect, icon, isSelected }) => {
 
  return (
    <NavLink
      style={{ textDecoration: "none" }}
      className={`sidebar-item ${isSelected ? "sidebar-item-selected" : ""}`}
      to={redirect}
    >
      {icon}
      <span>{name}</span>
    </NavLink>
  );
};

export default SideBarItem;
