import { useQuery } from "react-query";

import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import { useSelector } from "react-redux";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import "../../styles/common.scss";

import userSettingsServices from "../../services/userSettings,services/userSettings,services";
import UpdateUserSettings from "../../components/user-settings-components/UpdateUserSettings";
import { Avatar, Paper, Typography } from "@mui/material";
const UserSettings = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);

  const userId = localStorage.getItem("userId");
  console.log(userId);

  //fetch current user data using id
  const { isLoading, isError, data } = useQuery(
    ["userSettings"],
    async () => await userSettingsServices.getUserService(userId),
    {
      onError: (error) => {
        const responce = error;
        console.error(responce.message);
      },
    }
  );
  const user = data?.data.data;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }
  console.log(data.data.data);

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Settings"
        SubHeaderName="Manage user settings from here"
      />

      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        <Paper elevation={3} className="user-settings">
          <Avatar />
          <div className="profileItem">
            <Typography variant="h6">Name: {user.name}</Typography>
          </div>
          <div className="profileItem">
            <Typography variant="subtitle1">Role: {user.role}</Typography>
          </div>
          <div className="profileItem">
            <Typography variant="subtitle1">Email: {user.email}</Typography>
          </div>
          <div className="profileItem">
            <Typography variant="subtitle1">
              Phone: {user.phoneNumber}
            </Typography>
          </div>
          <UpdateUserSettings userId={userId} />
        </Paper>
      </div>
    </div>
  );
};

export default UserSettings;
