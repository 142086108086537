

import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

export default function BasicBreadcrumbs({ routes }) {
  

  return (
    <div role="presentation" >
      <Breadcrumbs aria-label="breadcrumb">
        {routes.map((route, index) => (
          <Link
            key={index}
            className={route.active ? "breadcrumb-active" : "breadcrumb-not-active"}
            underline="hover"
            color="inherit"
            to={route.to}
          >
            {route.name}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
