import React from "react";
import "./rightsidebar.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const RightSidebar = () => {
  return (
    <div className="right-sidebar-container">
      <div className="right-sidebar">
        <AccountCircleIcon sx={{ width: "40px", height: "40px" }} />
      </div>
    </div>
  );
};

export default RightSidebar;
