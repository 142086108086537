import axiosInstance from "../../helpers/auth.helper/auth.helper";

const engineOriginServices = {
  createEngineOriginService: (data) => axiosInstance.post("/admin/cars/engineOrigin/add", data),
  getAllEngineOriginService: () => axiosInstance.get("/admin/cars/engineOrigin/"),

  updateEngineOriginService: (data) => axiosInstance.put(`/admin/cars/engineOrigin/${data.id}`, data),
  deleteEngineOriginService: (data) => axiosInstance.delete(`/admin/cars/engineOrigin/${data}`),
};

export default engineOriginServices;
